<template>
  <v-container ma-0 fluid grid-list-lg>
    <v-layout align-content-center align-center justify-center style="text-align:center;">
      <v-flex xs3>
        <v-btn class="accent" fab>
          <v-icon class="white">mdi-cup</v-icon>
        </v-btn>
        <p class="text-xs-center">Soft</p>
      </v-flex>
      <v-flex xs3 class="text-xs-center">
        <v-btn class="accent" fab>
          <v-icon class="white">mdi-cup</v-icon>
        </v-btn>
        <p class="text-xs-center">Alchool</p>
      </v-flex>
      <v-flex xs3 class="text-xs-center">
        <v-btn class="accent" fab>
          <v-icon class="white">mdi-fork</v-icon>
        </v-btn>
        <p class="text-xs-center">Grignotte</p>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs6 v-for="item in items" :key="item.name">
        <v-card>
          <v-card-media contain height="auto" src="src">
            <v-card-title primary-title class="text-capitalize">{{item.name}}</v-card-title>
          </v-card-media>
          <v-card-text>prix : {{ item.price }} €</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon class="secondary">
              <v-icon class="white">mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      display: {
        soft: true,
        food: true,
        alcool: true
      },
      soft: [
        {
          name: "diabolo",
          price: 2.5,
          types: ["grenadine", "menthe", "pêche"],
          tags: {
            bio: true,
            local: true
          }
        },
        {
          name: "jus de fruit",
          price: 2.5,
          types: ["Pomme", "Orange", "Ananas"],
          tags: {
            local: true
          }
        }
      ]
    };
  },
  computed: {
    items() {
      return this.soft;
    }
  }
};
</script>
